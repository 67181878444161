import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import { motion, AnimatePresence } from "framer-motion"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import SectionTitle from "../components/section-title"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Images
import TwistedShapesLeft from "../assets/aboutus-twisted-shapes-left.svg"
import TwistedShapesRight from "../assets/aboutus-twisted-shapes-right.svg"
// import TwistedShapesResponsive from "../assets/aboutus-twisted-shapes-responsive.svg"
import Boy from "../assets/aboutus-boy.svg"
import Dashboard from "../assets/aboutus-dashboard.inline.svg"
import DashboardStripes from "../assets/aboutus-dashboard-stripes.svg"
import CardTwistedTwistedShapes from "../assets/aboutus-card-twisted-shapes.png"

// Logos
import JpmorganLogo from "../assets/logos/jpmorgan.png"
import WorldlineLogo from "../assets/logos/worldline.png"
// import BTLogo from "../assets/logos/BT.png"
import fit4youLogo from "../assets/logos/fit4you.png"
import blueairLogo from "../assets/logos/blueair.png"
import AVstoreLogo from "../assets/logos/AVstore.jpg"
import coleteOnlineLogo from "../assets/logos/colete-online.png"
import boosteroidLogo from "../assets/logos/boosteroid.png"
import aquaLogo from "../assets/logos/aqua.png"

// Icons
import PlayIcon from "../assets/play-btn.svg"
import PeopleIcon from "../assets/icons/people.svg"
import ArrowRightGreenIcon from "../assets/icons/arrow-right-green.svg"
import CreditCardIcon from "../assets/icons/creditcards.svg"
import GrowthIcon from "../assets/icons/growth.svg"
import DashboardIcon from "../assets/icons/dashboard.svg"
import ArrowRightIcon from "../assets/icons/arrow.svg"

// Logos
import BNRLogo from "../assets/logos/bnr.svg"

// Styles
import "../styles/pages/about.scss"

const HeroSection = () => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  return (
    <>
      <section className="section is-hero has-margin-bottom-8 has-margin-bottom-7-mobile">
        <div
          onClick={() => setActive(true)}
          onKeyDown={() => setActive(true)}
          tabIndex={0}
          role="button"
          className="play-btn-wrapper"
        >
          <span className="icon">
            <img src={PlayIcon} alt="Play Icon" />
          </span>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-4-fullhd is-5-tablet">
              <h1 className="title is-spaced main-title ">
                {t("about_hero_title")}
              </h1>
              <h2 className="subtitle has-text-white main-subtitle">
                {t("about_hero_subtitle")}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <AnimatePresence>
        {active && (
          <motion.div className="modal is-active">
            <div
              onClick={() => setActive(false)}
              onKeyDown={() => setActive(false)}
              className="modal-background"
              role="button"
              tabIndex={0}
              aria-label="close"
            ></div>
            <motion.div
              initial={{ scale: 0, opacity: 0, y: -100 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0, opacity: 0, y: 100 }}
              transition={{ duration: 0.3 }}
              className="content-wrapper"
            >
              <div className="close-wrapper">
                <button
                  onClick={() => setActive(false)}
                  className="modal-close has-background-default"
                  aria-label="close"
                ></button>
              </div>
              <div className="modal-content">
                <iframe
                  title="Twispay Video"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/a1cdr4WKokw"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

const Section2 = () => {
  const { t } = useTranslation()
  const leftItems = [
    {
      title: t("about_section2_left-item1_title"),
      text: "2013",
    },
    {
      icon: <ArrowRightGreenIcon />,
      title: t("about_section2_left-item2_title"),
      text: t("about_section2_left-item2_subtitle"),
    },
    {
      icon: <PeopleIcon />,
      title: t("about_section2_left-item3_title"),
      text: t("about_section2_left-item3_subtitle"),
      // logos: [
      //   { title: "J.P.Morgan", icon: JpmorganLogo, height: 26 },
      //   { title: "Worldline", icon: WorldlineLogo, height: 21 },
      //   // { title: "BT", icon: BTLogo, height: 67 },
      // ],
    },
  ]
  const rightItems = [
    {
      icon: <CreditCardIcon />,
      title: t("about_section2_right-item1_title"),
      subtitle: t("about_section2_right-item1_subtitle"),
    },
    {
      icon: <GrowthIcon />,
      title: t("about_section2_right-item2_title"),
      subtitle: t("about_section2_right-item2_subtitle"),
    },
    {
      icon: <DashboardIcon />,
      title: t("about_section2_right-item3_title"),
      subtitle: t("about_section2_right-item3_subtitle"),
    },
  ]
  return (
    <section className="section section-2 ">
      <div className="container">
        <div className="columns is-mobile cols-wrapper">
          <div className="column is-offset-1-fullhd is-4-fullhd is-5-desktop left-col">
            <div className="left-wrapper">
              <div className="img-wrapper">
                <img src={Boy} alt="Boy" />
              </div>
              <div className="content-wrapper is-flex">
                <img
                  src={CardTwistedTwistedShapes}
                  alt="Twisted Shapes"
                  className="twisted-shapes"
                />
                {leftItems.map(item => (
                  <div key={item.title} className="item-wrapper is-flex">
                    <span className="icon has-margin-right-4">{item.icon}</span>
                    <div>
                      <h6 className="title is-spaced has-margin-bottom-3">
                        {item.title}
                      </h6>
                      {item.text ? (
                        <p>{item.text}</p>
                      ) : (
                        <div className="is-flex is-wrap is-vcentered">
                          {item.logos.map(logo => (
                            <img
                              style={{ height: logo.height }}
                              className={`has-margin-right-4 ${logo.title}`}
                              key={logo.title}
                              src={logo.icon}
                              alt={logo.title}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="column is-offset-1-tablet is-5-fullhd is-6-tablet right-wrapper right-col">
            {rightItems.map(item => (
              <div key={item.title} className="columns item-wrapper">
                <div className="column is-2-desktop is-3-tablet icon-wrapper">
                  <span className="icon">{item.icon}</span>
                </div>
                <div className="column">
                  <h3 className="title is-spaced has-margin-bottom-3">
                    {item.title}
                  </h3>
                  <p className="subtitle is-6 is-pre-line ">{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const Section3 = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section section-3 has-margin-bottom-6 has-margin-bottom-4-mobile">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8-fullhd is-10-desktop">
            <h1 className="title is-2 is-spaced main-title has-text-centered">
              {t("about_section3_title")}
            </h1>
          </div>
        </div>
        <div className="columns is-vcentered is-mobile row-1-wrapper">
          <div className="column is-4-fullhd is-5-desktop is-offset-1-fullhd ">
            <div className="has-text-centered has-margin-bottom-5">
              <img width="81" src={BNRLogo} alt="BNR Logo" />
            </div>
            <h3 className="title is-spaced has-text-centered-mobile">
              {t("about_section3_item1_title")}
            </h3>
            <p className="subtitle">{t("about_section3_item1_subtitle")}</p>
          </div>
          <div className="column is-offset-1-desktop is-6-desktop">
            <Img fluid={imageFluid[0]} />
          </div>
        </div>
        <div className="columns is-vcentered ">
          <div className="column is-4-fullhd is-5-desktop is-offset-1-fullhd">
            <Img fluid={imageFluid[1]} />
          </div>
          <div className="column is-4-fullhd is-5-desktop is-offset-1">
            <h3 className="title is-spaced">
              {t("about_section3_item2_title")}
            </h3>
            <p className="subtitle">{t("about_section3_item2_subtitle1")}</p>
            <p className="subtitle">{t("about_section3_item2_subtitle2")}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section4 = () => {
  const { t } = useTranslation()
  const logos = [
    { name: "Fit4you", icon: fit4youLogo, height: 48 },
    // { name: "Blueair", icon: blueairLogo, height: 43 },
    { name: "AVstore", icon: AVstoreLogo, height: 35 },
    { name: "Colete-Online", icon: coleteOnlineLogo, height: 52 },
    { name: "Boosteroid", icon: boosteroidLogo, height: 38 },
    { name: "Aqua", icon: aquaLogo, height: 47 },
  ]
  return (
    <section className="section section-4">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8-fullhd is-10-desktop">
            <SectionTitle
              title={t("about_section4_title")}
              subtitle={t("about_section4_subtitle")}
              subtitleWidth="100%"
            />
            <div className="logos-wrapper is-flex is-centered-centered is-wrap">
              {logos.map(logo => (
                <img
                  src={logo.icon}
                  alt={logo.name}
                  key={logo.name}
                  className={logo.name}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section5 = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-5">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10-fullhd">
            <div className="content-wrapper">
              <div className="stripes-wrapper ">
                <img src={DashboardStripes} alt="stripes" />
              </div>
              <div className="columns">
                <div className="column">
                  <div className="dashboard-img">
                    <Dashboard />
                  </div>
                </div>
                <div className="column">
                  <div
                    style={{ height: "100%" }}
                    className="is-flex is-vspaced"
                  >
                    <div>
                      <h2 className="title main-title is-spaced has-margin-bottom-4">
                        {t("about_section5_title")}
                      </h2>
                      <p className="subtitle is-6 main-subtitle">
                        {t("about_section5_subtitle")}
                      </p>
                    </div>
                    <div className="ctas-wrapper is-flex is-vcentered has-margin-bottom-4 has-margin-bottom-0-mobile">
                      <a
                        href="https://merchant-stage.twispay.com/auth/signin"
                        className="button is-size-5 is-blue is-rounded  has-margin-right-5 has-margin-right-0-mobile"
                      >
                        {t("about_section5_cta1")}
                      </a>
                      <Link to="/pricing" className="is-size-5 pricing-btn">
                        {t("about_section5_cta2")}
                        <span
                          style={{ height: 13, width: 13 }}
                          className="icon has-margin-left-3"
                        >
                          <ArrowRightIcon />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const AboutPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeColor="#003772"
      stripeVid={images.heroVideo.publicURL}
      pageName="about"
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
    >
      <SEO title={t("About Twispay")} />
      <HeroSection />
      <Section2 />
      <Section3
        imageFluid={[
          images.BNRBuildingImage.childImageSharp.fluid,
          images.SolutionsImage.childImageSharp.fluid,
        ]}
      />
      <Section4 />
      <Section5 />
    </Layout>
  )
}

export const images = graphql`
  query {
    heroVideo: file(relativePath: { eq: "aboutus-video-short.mp4" }) {
      publicURL
    }
    BNRBuildingImage: file(relativePath: { eq: "BNR-building.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SolutionsImage: file(relativePath: { eq: "aboutus-solutions.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutPage
